import { inspection } from './inspection'
import { appraisal } from './appraisal'
import { leads } from './leads'

const forwarder = {
  supervisor_inspections: inspection,
  supervisor_appraisals: appraisal,
  supervisor_leads: leads,
}
export default forwarder
