export const financialExecutive = [
  { path: 'financing.warrantyAmountFixed', title: 'Precio de venta ', icon: 'mdi-currency-usd' },
  { path: 'financing.initialFixed', title: 'Pie', icon: 'mdi-currency-usd' },
  { path: 'financing.piePercentage', title: 'Pie%', icon: 'mdi-currency-usd' },
  { path: 'financing.quota', title: 'Cuotas', icon: 'mdi-currency-usd' },
  {
    path: 'financing.saleOrder.deal.closingReason.description',
    title: 'Motivo de cierre del deal',
    icon: 'mdi-close-circle-outline',
  },
  {
    path: 'closingReason.description',
    title: 'Motivo de cierre de la evaluación',
    icon: 'mdi-close-circle-outline',
  },
]
