export const appraisal = [
  { path: 'created', title: 'Creación', icon: 'mdi-calendar-blank-outline' },
  { path: 'appraiser.name', title: 'Tasador', icon: 'mdi-account' },
  { path: 'stock.name', title: 'Stock', icon: 'mdi-car' },
  { path: 'stock.status.description', title: 'Estado del Stock', icon: 'mdi-car' },
  { path: 'deal.lead.sale.stock.bono', title: 'Bono de renovación', icon: 'mdi-currency-usd' },
  { path: 'agreement.response.comment', title: 'Comentario tasador', icon: 'mdi-comment-text-outline' },
  { path: 'agreement.request.comment', title: 'Comentario ejecutivo', icon: 'mdi-comment-text-outline' },
  { path: 'closingReason.description', title: 'Motivo de cierre', icon: 'mdi-close-circle-outline' },
]
